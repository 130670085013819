export function checkUsername(username) {
  var words = ["gmail", "hotmail", "yahoo", "msn", "live", "com", "br"];
  var check = true;

  words.map(word => {
    if (username.match(word)) {
      check = false;
    }
  });

  return check;
}